.client-management-page-with-sidebar {
  width: 80%;
  transition: 0.6s;
  padding-top: 60px;
  /* margin-left: 200px; */
}
.map-box {
  background: #f6faff;
  /* border: 4px solid #ffffff; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
.map-box-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #040812;
}
.legend-color-box {
  width: 18px;
  height: 18px;
  border-radius: 10px;
}

.tab-btn {
  position: absolute;
  background: #dddddd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  color: "white";
  font-weight: 600;
  padding: 10px 15px 10px 15px;
  border: transparent;
}

.poor-btn {
  background-color: #d22f4c;
  color: #fff;
  border: none;
}
.next-prev-btn {
  border: none;
  border-radius: 5px;
  background-color: #f1f1f1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* background-color: #d5dcee; */
}
.stack-bar-label {
  width: 20px;
  height: 20px;
  border-radius: 12px;
}

/* ========================== client view */
.personal-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  color: #0c3094;
}

.view-sub-title{
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
}
.info-box{
  box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  /* border: 1px solid #0c3094; */
  border-radius: 5px;
}

.view-info-box-value {
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #262d47;
}

.personal-sub-title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  /* color: #262d47; */
}

.view-info-box-key {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #262d47;
}

.business-title {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #262d47;
}

.view-info-box {
  border-radius: 5px;
  border: solid 0.5px #bad2f3;
  width: 100%;
  background-color: #f6faff;
}

.view-document-title {
  width: 100%;

  border-radius: 5px;
  background-color: #4d5a88;
}
.view-document-title-text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.view-document-title-btn {
  border-radius: 5px;
  background-color: #e3e4e4;
  border: none;
  color: #000;
}
.view-kyc-doc-box {
  border-radius: 17.5px;
  background-color: #f6faff;

  /* height: 50vh; */
}
.view-kyc-doc-box-img {
  width: 189px;
  height: 119px;
}
.view-kyc-doc-title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #293952;
}
.financial-doc-box-1 {
  /* width: 300px;
  height: 300px; */
  border-radius: 17.5px;
  background-color: #bcc0c7;
}
.doc-Not-Uploaded {
  width: 149.5px;
  height: 31px;
  /* margin: 0 7px 38px 14px;
  padding: 11px 13.5px 9.5px 15.5px; */
  border-radius: 5px;
  background-color: #9c230c;
  color: #fff;
}