.dashboard-page {
  width: 100%;
  padding-top: 60px;
  transition: 0.6s;
}

.dashboard-page-with-sidebar {
  width: 80%;
  transition: 0.6s;
  padding-top: 60px;
}
.prospects-box {
  background: linear-gradient(306.39deg, #2c88ad 41.15%, #bfecff 81.51%);
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 100%;
  width: 100%;
}
.Sanctions-box {
  background: linear-gradient(287.79deg, #aa6a55 31.14%, #ffecd6 94.13%);
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 100%;
  width: 100%;
}
.Disbursal-box {
  background: linear-gradient(285.47deg, #2750c1 38.22%, #c0d1ff 82.1%);
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 100%;
  width: 100%;
}
.AvailableOrders-box {
  background: linear-gradient(279.79deg, #db8a2c 21.67%, #ffd29d 78.43%);
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 100%;
  width: 100%;
}
.OutstandingOrders-box {
  background: linear-gradient(280.98deg, #2d88ae 17.13%, #b2e3f8 77.52%);
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 100%;
  width: 100%;
}
.Total-RepOrders-box {
  background: linear-gradient(279.79deg, #ae6f5a 21.67%, #fde9d3 78.43%);
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 100%;
  width: 100%;
}
.dashboar-box-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 44px;
  color: #000000;
}
.dashboard-card-title-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}
.fytd-box {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  height: 100%;
}
.fytd-value-bg {
  background: #d9d9d9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.fytd-value-color {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #0c3094;
}

.fytd-box2 {
  background: #ededed;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
.fytd-box2-text {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
}
.repayment-box {
  background: #eeeeee;
  border-radius: 12px;
  /* height: 100%; */
  /* width: 100%; */
}
.legend-color-box {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.emptyProgressBar {
  background-color: #e7e4da;
  height: 10px;
  overflow: hidden;
  position: relative;
}
.fillingProgressBar {
  background-image: linear-gradient(to right, #2c5d71, #3089a9);
  height: 100%;
  width: 100%;
  position: absolute;
}
.fillingProgressBar2 {
  background-image: linear-gradient(to right, #8b4e3b, #af7e6f);
  height: 100%;
  width: 100%;
  position: absolute;
}
.fillingProgressBar3 {
  background-image: linear-gradient(to right, #0c2c85, #324989);
  height: 100%;
  width: 100%;
  position: absolute;
}
.fillingProgressBar4 {
  background-image: linear-gradient(to right, #965912, #bb9b76);
  height: 100%;
  width: 100%;
  position: absolute;
}
.percentage {
  color: #5273e6;
  font-size: 12px;
  color: #fff;
}
.mainProgressBarDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}

.date-input-box {
  border: none;
  background: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
