.ubfc-main-page {
    width: 100%;
    padding-left: 5%;
    transition: 0.6s;
  }
  .ubfc-main-page-with-sidebar {
    width: 96%;
    padding-left: 14%;
    transition: 0.6s;
  }
  .ubfc-merchant-title {
    color: #010101;
    text-align: left;
    font-size: 18px;
  }
  