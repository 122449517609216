.ubfc-report-main-page {
    width: 100%;
    padding-left: 4%;
    transition: 0.6s;
  }
  .ubfc-report-main-page-with-sidebar {
    width: 95%;
    padding-left: 12.5%;
    transition: 0.6s;
  }
  .excel-upload-btn {
    border: none;
    background-color: #37434f;
    color: #fff;
    border-radius: 5px;
  }
  