.ananya-kpi-main-box {
  width: 100%;
  padding-left: 115px;
  transition: 0.6s;
}

.ananya-kpi-main-box-with-sidebar {
  width: 90%;
  padding-left: 200px;
  transition: 0.6s;
}

.anayan-kpi-sub-box {
  border-radius: 15px;
  padding: 5px 5px 5px 5px;
  box-shadow: 0 0 6.9px 1.1px rgba(0, 0, 0, 0.18);
}

.ananya-kpi-box-title {
  font-size: 20px;
  font-weight: 600px;
}

.kpi-map-box {
  height: 520.5px;
  border-radius: 17.5px;
  box-shadow: 0 0 6.9px 1.1px rgba(0, 0, 0, 0.18);
  background-color: #fefefe;
}

.map-chart-title {
  width: 500.5px;
  height: 15.5px;
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #010101;
}

.shipment-heading-color {
  background-color: #d0d9ec;
}

.anannya-kpi-box1 {
  width: 250px;
  height: 242.5px;
  border-radius: 17.5px;
  box-shadow: 0 0 4.1px 0.4px rgba(11, 32, 87, 0.26);
  background-color: #fff;
}

.ananya-box1-no {
  margin: auto;
  display: flex;
  justify-content: center;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #010101;
}

.anannya-kpi-box1-title {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  display: flex;
  justify-content: center;
}
