.insight-main-box {
  width: 100%;
  /* padding-left: 100px; */
  padding-top: 60px;
  transition: 0.6s;
}

.insight-main-box-with-sidebar {
  width: 95%;
  padding-left: 11%;
  transition: 0.6s;
  padding-top: 2%;
}
.doc-status-box {
  /* width: 1220px; */
  /* height: 106.5px; */
  margin: 23.5px 0px 24px 13px;
  padding: 13px 31px 14px 23.5px;
  border-radius: 17.5px;
  box-shadow: 0 0 4.3px 0.7px rgba(14, 103, 159, 0.17);
  background-color: #fff;
}

.Documents-Status-subtitle {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #101d3f;
  margin-top: 10px;
}

.pie-box-insight {
  width: 500px;
  /* height: 411.5px; */
  /* margin: 24px 18.5px 45.5px 13px; */
  padding: 0px 55px 18.5px 9.5px;
  border-radius: 17.5px;
  box-shadow: 0 0 4.3px 0.7px rgba(14, 103, 159, 0.17);
  background-color: #fff;
}

.line-box {
  width: 700px;
  padding: 63px 11px 41px 0;
  border-radius: 17.5px;
  box-shadow: 0 0 4.3px 0.7px rgba(14, 103, 159, 0.17);
  background-color: #fff;
}

.icon-color-box-blue {
  margin: 0px 8px 52px 33px;
  padding: 9.5px 9px 11px 10px;
  border-radius: 12.5px;
}

.color-box-text {
  width: 223px;
  height: 11px;

  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #a1a2af;
}

.color-box-number {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5270ad;
}

.insight-table {
  overflow: auto;

  border-radius: 17.5px;
  box-shadow: 0 0 4.3px 0.7px rgba(160, 188, 205, 0.56);
  background-color: #fff;
}

.table-top-icons {
  float: right;
  margin-right: 130px;
  margin-bottom: 10px;
  margin-top: -10px;
}

.table-top-icons-with-sidebar {
  float: right;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: -10px;
}

.labelColor {
  width: 10px;
  height: 10px;
  background-color: #6fadda;
  border-radius: 2px;
}
.CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}
.Vibrant-GrowCaps-Private-Limited {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #101d3f;
}

.legend-color-box {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}
.order-tracker-details-path {
  color: #010101;
  text-align: left;
  font-size: 18px;
}
