.active-page-order-tracker-details-view {
  width: 94%;
  padding-left: 170px;
}
.active-page-with-sidebar-order-tracker-view {
  width: 94%;
  padding-left: 170px;
  /* transition: 0.6s; */
}
.order-tracker-details-path {
  color: #010101;
  text-align: left;
  font-size: 18px;
}

.buyer-details-box {
  margin: 17px 13px 11.5px 0px;
  padding: 10px 35.5px 5px 9px;
  border-radius: 5px;
  box-shadow: 0 0 3.4px 0.1px rgba(48, 82, 152, 0.18);
  background-color: #fff;
}

.buyer-box-title {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #010101;
}
.buyer-box-sub-title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  line-height: normal;
  color: rgb(38, 36, 36);
}
.Ordered-Products-title {
  margin: 0 580.5px 7.5px 0;

  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #010101;
}

.Stepper-title {
  margin: 0 718px 7.5px 30px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #010101;
}
.step {
  padding: 12.5px 17.5px 15.5px 9.5px;
  border-radius: 5px;
  line-height: normal;
}
.po-bill-invoice {
  color: #fff;
  background-color: rgb(72, 99, 178);
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  display: flex;
}
.po-bill-invoice-title {
  font-size: 18px;
  font-weight: 500;
}
.card-footer-title {
  font-size: 12px;
  color: rgb(81, 80, 80);
}
.unuploaded-doc {
  background-color: red;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding: 0 10px 0 10px;
}
.uploaded-doc {
  background-color: green;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding: 0 10px 0 10px;
}
.shipmentCareTitle {
  font-size: 14px;
  font-weight: 500;
}
.shipment-heading-color {
  background-color: #d1e0fe;
}
