@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;

  /* background-color: #f9f9f9; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* scroller css */

/* In your CSS file */

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #0c9696; /* Green color for the thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Light grey color for the track */
}
